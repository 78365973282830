import React, { useContext } from "react"
import { Formik, Form, ErrorMessage } from "formik"
import FormErrors from "../../../FormErrors"
import * as Yup from "yup"
import { FormControl, Box, TextField, Typography } from "@material-ui/core"
import Button from "../../../Button"

import { useStyles } from "./style"
import AuthContext from "../../../../context/auth/authContext"

const ForgotPassword = () => {
  const classes = useStyles()
  const authContext = useContext(AuthContext)

  const { loading, forgotPassword } = authContext
  

  const initialValue = {
    email: ""
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),
  })

  const onSubmit = value => {
    forgotPassword(value)
  }

  return (
    // <Container >
    <Box>
    <Typography align="center" className={classes.title}>
      Forgot Password
    </Typography>
    <Typography align="center" className={classes.subtitle}>
      Enter your email address to reset password
    </Typography>
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, getFieldProps }) => (
        <Form noValidate autoComplete="off" className={classes.form}>
          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Email Address</Typography>
            <TextField
              name="email"
              id="email"
              {...getFieldProps("email")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="email" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.submit}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              loading={loading}
            >
             Reset Password
            </Button>
          </FormControl>
        </Form>
      )}
    </Formik>
  </Box>
   
    
    // </Container>
  )
}

export default ForgotPassword
