import React from "react"
import SEO from "../../components/seo"
import AuthLayout from "../../components/Layout/AuthLayout"
import PasswordForgot from "../../components/Layout/AuthLayout/ForgotPassword"

const ForgotPassword = () => {
  return (
    <>
    <SEO title="Home" />
      <AuthLayout>
        <PasswordForgot />
      </AuthLayout>
    </>
  )
}

export default ForgotPassword
